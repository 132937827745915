<template>
  <link rel="stylesheet" href="src/assets/normalize.css">
  <div class="background">
    <div class="part1-2">
      <div class="bigBox">
        <div class="part1">
          <el-carousel height="8rem">
            <el-carousel-item v-for="item in homePic" :key="item">
              <img :src="item.src" style="height:100%;width:100%;" alt="图片丢失了" />
            </el-carousel-item>
          </el-carousel>
          <!-- <div class="title">
            <p>云端协力，</p>
            <p>齐心制造，</p>
            <p>实现共享共赢</p>
            <button class="down" @click="this.$router.push('/RequestDetail')" v-show="isLogin === '1' && this.role === 'enterprise' ">发布需求</button>
          </div> -->
        </div>
        <div id="field"></div>
        <div class="part2">
          <!--服务领域-->
          <el-space direction="vertical" class="vertical">
            <el-space>
              <div class="card card1">
                <div class="card_pic1">
                  <div class="shape-one"></div>
                  <div class="shape-two"></div>
                </div>
                <p>产品创新设计</p>
                <p>Product innovation design</p>
                <p>外观设计：   CMF输出</p>
                <p>结构设计：   样机验证</p>
              </div>
              <div class="card">
                <div class="card_pic2">
                  <div class="shape-one"></div>
                  <div class="shape-two"></div>
                </div>
                <p>用户体验设计</p>
                <p>User experience design</p>
                <p>用户调研   信息架构</p>
                <p>产品研究   原型设计和测试</p>
              </div>
              <div class="card">
                <div class="card_pic3">
                  <div class="shape-one"></div>
                  <div class="shape-two"></div>
                </div>
                <p>工程研发设计</p>
                <p>Engineering R&D design</p>
                <p>模具设计    自动化设计</p>
                <p>机械设计    生产线整合</p>
              </div>
            </el-space>
            <el-space>
              <div class="card">
                <div class="card_pic4">
                  <div class="shape-one"></div>
                  <div class="shape-two"></div>
                </div>
                <p>传播设计</p>
                <p>Communication design</p>
                <p>品牌设计   包装设计</p>
                <p>导视设计   VI系统设计</p>
              </div> <div class="card">
              <div class="card_pic5">
                <div class="shape-one"></div>
                <div class="shape-two"></div>
              </div>
              <p>展示空间设计</p>
              <p>Exhibition space design</p>
              <p>展厅设计   商业展示设计</p>
              <p>空间改造   形象提升设计</p>
            </div> <div class="card">
              <div class="card_pic6">
                <div class="shape-one"></div>
                <div class="shape-two"></div>
              </div>
              <p>衍生设计</p>
              <p>Derivative design</p>
              <p>IP形象设计   产品迭代策略</p>
              <p>产品拓扑优化</p>
            </div>
            </el-space>
          </el-space>
        </div>
      </div>
    </div>

    <div class="part3" id="advantage">
      <p>我们创造一个属于工业设计的生态系统</p>
      <div class="eng">We creat an ecosystem of industrial design and provide outsourcing services to small and medium sized enterprises</div>
      <button class="demand" @click="this.$router.push('/RequestDetail')" v-show="isLogin === '1'">发布需求</button>
    </div>
    <!--平台优势-->

    <div class="part4">
      <div class="part4_1">
        <p>平台优势</p>
        <p>让不同地域的项目人员、平台工作人员和需求方有效沟通和开</p>
        <p>展协同研发设计，保障服务质量并提高产出效率</p>
      </div>
      <div>
        <div class="picture1">
          <div class="collapse">
            <el-collapse v-model="activeName" accordion @change="checkCollapse">
              <el-collapse-item name="1">
                <template #title>
                  <div  class="collapseTitle">资源共享</div>
                  <i></i>
                </template>
                <div class="collapseContent">
                  连接汇聚海量设计资源，提供云端设计环境和产品设计数据库，汇集资源共享经验，满足制造企业智能化转型升级。
                </div>
              </el-collapse-item>
              <el-collapse-item name="2">
                <template #title>
                  <div  class="collapseTitle">项目对接</div>
                  <i></i>
                </template>
                <div class="collapseContent">
                  自由发布项目任务，远程组建团队获取项目任务，成员远程协作、进度实时更新、项目状态透明、技术资料安全保密。
                </div>
              </el-collapse-item>
              <el-collapse-item name="3">
                <template #title>
                  <div  class="collapseTitle">协同设计</div>
                  <i></i>
                </template>
                <div class="collapseContent">
                  优化资源配置，精益化系统提升效率、节省项目时间。随进度更新资源分配，帮您预测项目风险，最大化体现协同开发优势。
                </div>
              </el-collapse-item>
              <el-collapse-item name="4">
                <template #title>
                  <div  class="collapseTitle">交易管理</div>
                  <i></i>
                </template>
                <div class="collapseContent">
                  云平台把控需求方服务的质量还有交易合理性，最大可能的提供公正安全的交易环境。
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>

        </div>
        <!--Part4图片-->
        <div class="picture2" v-show="activeName === '1'"></div>
        <div class="picture2_2 picture2" v-show="activeName === '2'"></div>
        <div class="picture2_3 picture2" v-show="activeName === '3'"></div>
        <div class="picture2_4 picture2" v-show="activeName === '4'"></div>

      </div>
    </div>

    <Part2 id="process"/>

    <div class="part6">
      <div class="top">
        <p>平台拥有更多成功案例</p>
        <p>通过对设计环节的精确控制，加以微电子技术、机械设计技术、互联网技术等诸多技术的联合参与，最终实现外管优美、结构合理、功能全面稳定的产品</p>
        <button @click="this.$router.push('/Case?caseType=产品设计');">更多案例</button>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="part6_pic">
            <div v-show="part6Choice === 1">
              <div class="left_up">
                <img src="../../images/part6_pic1.jpg" alt="" style="width: 8.6rem; height: 5.35rem">
              </div>
              <div class="left_down">
                <p>
                  智能口腔综合治疗仪
                </p>
                <div class="introduce">
                  用人机工程设计、微电脑控制、模块化生产工艺、互联网硬件、是一款性能稳定、外形美观的口腔诊疗设备，适用于口腔医疗单位对口腔疾病患者的诊断、治疗和手术。
                </div>
              </div>
            </div>

            <div v-show="part6Choice === 2">
              <div class="left_up">
                <img src="../../images/part6_pic2.jpg" alt="" style="width: 8.6rem; height: 5.35rem">
              </div>
              <div class="left_down">
                <p>
                  糖尿病足检测仪
                </p>
                <div>
                  分体式设计、佩戴舒适，操作简单智能，配有分离式充电宝，方便携带。
                </div>
              </div>
            </div>

            <div v-show="part6Choice === 3">
              <div class="left_up">
                <img src="../../images/part6_pic3.jpg" alt="" style="width: 8.6rem; height: 5.35rem">
              </div>
              <div class="left_down">
                <p>
                  医用耦合仪
                </p>
                <div>
                  根据使用人群的操作习惯进行整体设计，保证了设备使用及运行。
                </div>
              </div>
            </div>

            <div v-show="part6Choice === 4">
              <div class="left_up">
                <img src="../../images/part6_pic4.jpg" alt="" style="width:8.7rem;">
              </div>
              <div class="left_down">
                <p>
                  干式荧光免疫分析仪
                </p>
                <div>
                  脱离出医疗产品的直觉范畴，更贴近于家用电器，增进了他的亲和力。
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="right_item1 right_item" @click="this.part6Choice = 1">
            <div class="item_pic" :style="zoom"></div>
            <div class="intro">
              <p>
                智能口腔综合治疗仪
              </p>
              <div>
                用人机工程设计、微电脑控制、模块化生产工艺、互联网硬件...
              </div>
            </div>
          </div>
          <div class="right_item2 right_item"  @click="this.part6Choice = 2">
            <div class="item_pic"></div>
            <div class="intro">
              <p>
                糖尿病足检测仪
              </p>
              <div>
                分体式设计、佩戴舒适，操作简单智能，配有分离式充电宝，方便携带...
              </div>
            </div>
          </div>
          <div class="right_item3 right_item"  @click="this.part6Choice = 3">
            <div class="item_pic"></div>
            <div class="intro">
              <p>
                医用耦合仪
              </p>
              <div>
                根据使用人群的操作习惯进行整体设计，保证了设备使用及运行...
              </div>
            </div>
          </div>
          <div class="right_item4 right_item"  @click="this.part6Choice = 4">
            <div class="item_pic"></div>
            <div class="intro">
              <p>
                干式荧光免疫分析仪
              </p>
              <div>
                脱离出医疗产品的直觉范畴，更贴近于家用电器，增进了他的亲和力...
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="part7">
      <p>加入我们的平台，您将拥有更多选择。</p>
      <p>Join our platform and you will have more choices.</p>
    </div>
    <div class="part8">
      <div class="coop">

      </div>
    </div>
  </div>
</template>

<script>
import Part2 from "@/components/HomeComponents/Part2";
import {ref} from "vue";
export default {
  name: 'Platform',
  data(){
    const activeName = ref('1')
    return{
      homePic:[],

      isLogin: '0',

      activeName:'1',
      lastActiveName:'1',
      part5SwitchCardVisual: true,
      part5SwitchCount:1,
      part5SwitchDemand:'',
      part5SwitchResolver:'',

      submitRequestForm:{
        requestType:'',
        requestPhone:'',
        requestCheckCode:''
      },
      submitRequestVisual: false,
      provideServiceForm:{
        serviceType:'',
        servicePhone:'',
        serviceCheckCode:''
      },
      provideServiceVisual: false,

      part6Choice:1,
    }
  },
  mounted() {
    this.isLogin = window.sessionStorage.getItem("login");
    this.getHomePic();
    if(this.$route.query.id !== undefined){
      console.log(this.$route.query.id);

      clearTimeout(this.timer);  //清除延迟执行

      this.timer = setTimeout(()=>{   //设置延迟执行
        console.log('ok');
        document.querySelector("#"+ this.$route.query.id ).scrollIntoView(true)
      },1000);


    }

  },

  methods:{
    getHomePic(){
      this.$http({
        method: 'post',
        url: '/getHomeHeaderPics',
        data: '',
      }).then(res => {
        for(let i=0;i<res.data.homePics.length;i++){
          this.homePic.push({
            src: res.data.homePics[i],
          })
        }
      })
    },
    //part4
    checkCollapse(){
      if (this.lastActiveName!=='1' && this.activeName===''){
        this.activeName = '1';
        this.lastActiveName = '1';
      }
      if (this.activeName==='' && this.lastActiveName === '1'){
        this.activeName = '4';
      }
      this.lastActiveName = this.activeName;
    },


  },
  components: {
    Part2,
  }
}
</script>

<style scoped>
.background{
  width: 1920px;
  background-color: white;
}

video{
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.title{
  position: absolute;

  top: 1.75rem;
  left: 1.8rem;
  height: 4.42rem;
  font-weight: 300;
  font-size: .82rem;
  /*background-color: pink;*/
}

.title p{
  display: block;
  font-size: .82rem;
  color: #ffffff;
  width: 5rem;
  height: .76rem;
  margin-bottom: .3rem;
  margin-top: 0;
}

.title :nth-child(3){
  margin-bottom: .95rem;
}

.title button{
  /*position: relative;*/
  float: left;
  width: 1.65rem;
  height: .6rem;
  font-size: .21rem;
  line-height: .6rem;
  text-align: center;
  /*border: .01rem solid transparent;*/
  background-color: #ec414d;
  /*border: .01rem solid transparent;*/
  margin-right: .32rem;
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  color: #ffffff;
  transition: all 1s;
}

.title button:hover{
  background-size: 300%;

  color: #ffffff;
}


.part1{
  height: 9.32rem;
  width: 100%;
}



.part1 .part1Title{
  /*background: url("../../images/geo_1.png");*/
  background-size: 100%;
  position: absolute;
  width: 15.6rem;
  height: 4.7rem;
  left: 1.8rem;
  bottom: 6.25rem;
  border: .01rem solid transparent;
  border-radius: .1rem;
  padding-top: .03rem;
  background-color: rgb(235,66,77);
  /*background-image: url("../../images/geometry.png");*/

}


.part1Title .geo_1{
  /*float: left;*/
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  left: .36rem;
  top: 1.42rem;
  /*margin-top: 2.36rem;*/
  /*background-color: pink;*/
  background: url("../../images/geo_1_1.png") no-repeat;
}


.part1Title .geo_2{
  position: absolute;
  width: 2.2rem;
  height: 2.4rem;
  left: 5.78rem;
  top: .58rem;
  /*margin-left: 5.78rem;*/
  /*background-color: pink;*/
  background: url("../../images/geo_1_2.png") no-repeat;
}

.part1Title .geo_3{
  position: absolute;
  width: 2.8rem;
  height: 2.8rem;
  left: 13.22rem;
  top: 2.16rem;
  /*margin-left: 13.24rem;*/
  margin-left: .42rem;
  /*background-color: pink;*/
  background: url("../../images/geo_1_3.png") no-repeat;

}


.part1 .part1Title div:nth-child(1){
  height: .36rem;
  font-size: .36rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: .3rem;
  margin-top: .7rem;
  color: #ffffff;
  line-height: .4rem;
  /*letter-spacing: .05rem;*/
}
.part1 .part1Title div:nth-child(2){
  height: .26rem;
  font-size: .24rem;

  text-align: center;
  margin-bottom: .1rem;
  color: #ffffff;
  line-height: .26rem;
  /*letter-spacing: .05rem;*/

}
.part1 .part1Title div:nth-child(3){
  height: .26rem;
  font-size: .24rem;

  text-align: center;
  /*margin-bottom: .1rem;*/
  color: #ffffff;
  line-height: .26rem;
  /*letter-spacing: .05rem;*/

}

.part1-2 .bigBox{
  position: relative;
}

.part2{
  height: 9.8rem;
  background-color: #ffffff;
}

.part2 .vertical{
  position: absolute;
  left: 2.9rem;
  bottom: .5rem;
  overflow: hidden;
}

/*.part2 .card::after{*/
/*    position: absolute;*/
/*    content: '';*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    width: 100%;*/
/*    height: 50%;*/
/*    opacity: 0;*/
/*    background-color: #ec414d;*/
/*    transition: all 2s;*/
/*}*/

/*.part2 .card::before{*/
/*  position: absolute;*/
/*  content: '';*/
/*  left: 0;*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*  height: 50%;*/
/*  opacity: 0;*/
/*  background-color: #ec414d;*/
/*  transition: all 2s;*/
/*}*/

.part2 .card{
  background-color: #f5f5f5;
  /*background-image: linear-gradient(0deg,transparent 0%,#ec414d 100%);*/
  background-image: linear-gradient(#212121,#212121);
  background-repeat: no-repeat;
  background-size: 0;
  background-position: left;
  transition: all linear .7s;
}
.part2 p{
  transition: all linear .7s;
  font-weight: 300;
}


.part2 .vertical .card:hover{
  background-size: 300%;
  /*transition: all linear 1s;*/
}
.part2 .vertical .card:hover p{
  color: #ffffff;
  /*font-weight: 400;*/
}

.part2 .vertical .card:hover .card_pic1{

  background-image: url("../../images/part2_card_pic1_w.png");
}
.part2 .vertical .card:hover .card_pic2{
  z-index: 1;
  background-image: url("../../images/part2_card_pic2_w.png");
}
.part2 .vertical .card:hover .card_pic3{
  background-image: url("../../images/part2_card_pic3_w.png");
}
.part2 .vertical .card:hover .card_pic4{
  z-index: 1;

  background-image: url("../../images/part2_card_pic4_w.png");
}
.part2 .vertical .card:hover .card_pic5{
  z-index: 1;

  background-image: url("../../images/part2_card_pic5_w.png");
}
.part2 .vertical .card:hover .card_pic6{
  z-index: 1;

  background-image: url("../../images/part2_card_pic6_w.png");
}

.part2 .card{
  position: relative;
  width: 4.2rem;
  height: 3.44rem;
  box-sizing: border-box;
  border-radius: .05rem;
  margin-right: .38rem;
  margin-bottom: .45rem;
  padding-top: .6rem;
  background-color: #f5f5f5;
  overflow: hidden;

}



.part2 .card .card_pic1{
  background: url("../../images/part2_card_pic1.png");
  background-size: 100%;
  width: .7rem;
  height: .69rem;
  margin-bottom: .2rem;
  margin-left: .5rem;
  transition: all linear .7s;


}

.part2 .card .card_pic2{
  background: url("../../images/part2_card_pic2.png");
  background-size: 100%;
  width: .69rem;
  height: .69rem;
  margin-bottom: .2rem;
  margin-left: .5rem;
  transition: all linear .7s;

}

.part2 .card .card_pic3{
  background: url("../../images/part2_card_pic3.png");
  background-size: 100%;
  width: .7rem;
  height: .7rem;
  margin-bottom: .2rem;
  margin-left: .5rem;
  transition: all linear .7s;

}

.part2 .card .card_pic4{
  background: url("../../images/part2_card_pic4.png");
  background-size: 100%;
  width: .7rem;
  height: .7rem;
  margin-bottom: .2rem;
  margin-left: .5rem;
  transition: all linear .7s;
}

.part2 .card .card_pic5{
  background: url("../../images/part2_card_pic5.png");
  background-size: 100%;
  width: .69rem;
  height: .7rem;
  margin-bottom: .2rem;
  margin-left: .5rem;
  transition: all linear .7s;

}

.part2 .card .card_pic6{
  background: url("../../images/part2_card_pic6.png");
  background-size: 100%;
  width: .68rem;
  height: .64rem;
  margin-bottom: .2rem;
  margin-left: .5rem;
  transition: all linear .7s;

}

.part2 .card p{
  height: .4rem;
  font-size: .4rem;
  margin-left: .5rem;
  margin-bottom: .15rem;
  /*margin-top: 0;*/
  color: #212121;
  z-index: 2;
}
.part2 .card :nth-child(3){
  height: .16rem;
  font-size: .14rem;
  margin-left: .5rem;
  margin-bottom: .25rem;
  color: #212121;
}
.part2 .card :nth-child(4){
  height: .2rem;
  font-size: .18rem;
  margin-left: .5rem;
  margin-bottom: .1rem;
  color: #212121;
}
.part2 .card :nth-child(5){
  height: .2rem;
  font-size: .18rem;
  margin-left: .5rem;
  margin-bottom: .1rem;

  color: #212121;
}


.part2 .card .shape-one{
  /*contain: '';*/
  width: 2.5rem;
  height: 2.5rem;
  background: #ec414d;
  opacity: 0.4;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  left: 3rem;
  top: -1.2rem;
  transition: all linear .7s;
}

.part2 .card .shape-two{
  /*contain: '';*/
  width: 3.73rem;
  height: 3.73rem;
  background: #ec414d;
  opacity: 0;
  position: absolute;
  border-radius: 50%;
  /*z-index: 1;*/

  left: 2.4rem;
  top: -1.8rem;
  transform: scale(0);
  transition: all linear .7s;
}

.part2 .card:hover .shape-one{
  transform: scale(1);
  /*transform: all linear 1s;*/
}

.part2 .card:hover .shape-two{
  transform: scale(1);
  opacity: 0.4;

}


.part3{
  height: 2rem;
  background: url("../../images/part3_pic.png") center;
  /* background-size: 100% 100%; */
  border: .01rem solid transparent;
  padding-top: .26rem;
}

.part3 p{
  font-size: .36rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  margin-bottom: .1rem;
}

.part3 .eng{
  width: 6.32rem;
  font-size: .18rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
}

.part3 .demand{
  position: absolute;
  width: 1.65rem;
  height: .6rem;
  top: .68rem;
  right: 5.4rem;
  font-size: .21rem;
  background-color: #ffffff;
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  /* color: #212121; */

}

.part3 .service{
  position: absolute;
  width: 1.65rem;
  height: .6rem;
  top: .68rem;
  right: 3.15rem;
  font-size: .21rem;
  background-color: #ffffff;
  /*border: .01rem solid transparent;*/
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#ffffff 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  color: #ffffff;
}

.part3 .demand:hover{
  background-size: 300%;
  transition: all linear 1s;
  color: #ffffff;

}


.part3 .service:hover{
  background-size: 300%;
  transition: all linear 1s;
  color: #212121;

}

.part4{
  background: url("../../images/part4_pic1.png") center;
  background-size: 100% 100%;
  position: relative;
  height: 9.8rem;
  padding-top: 1rem;
  /*text-align: center;*/
}

.part4_1 p:nth-child(1){
  width: 1.46rem;
  height: .4rem;
  font-size: .36rem;
  font-weight: 700;
  color: #212121;
  /*text-align: center;*/
  margin: 0 auto;
  margin-bottom: .25rem;
}


.part4_1 p:nth-child(2){
  width: 6.5rem;
  height: .26rem;
  /*text-align: center;*/
  font-size: .24rem;
  color: #212121;
  margin: 0 auto;
  margin-bottom: .1rem;

}

.part4_1 p:nth-child(3){
  width: 6.5rem;
  height: .26rem;
  /*margin: 0 auto;*/
  font-size: .24rem;
  color: #212121;
  margin: 0 auto;
  text-align: center;

}

.part4 .picture1{
  position: absolute;
  width: 7.84rem;
  height: 4.82rem;
  left: 2rem;
  bottom: 2.03rem;
  background-color: #ffffff;
  box-shadow: #d4d2d2 0 .15rem .2rem;
  -webkit-box-shadow: #d4d2d2 0 0 .2rem;
  -moz-box-shadow: #d4d2d2 0 0 .2rem;
  border-radius: .1rem;
}

.part4 .picture1 .collapse{
  height: 3.93rem;
  width:70%;
  margin-top: .44rem;
  margin-left: .5rem;
  font-size: .24rem;
}

.part4 .picture1 .collapse i{
  position: absolute;
  left: 0px;
  width: 0px;
  height: 100%;
  background-color: #ec414d;
  transition: .7s all linear;
}

.part4 .picture1 .collapse .collapseTitle{
  position: absolute;
  left: 0px;
  height: 100%;
  font-size: .24rem;
  z-index:1;
}

.part4 ::v-deep .el-collapse-item__header{
  position: relative;
}

.part4 ::v-deep .el-collapse-item__header:hover i{
  /* width: 100%; */
}

.part4 ::v-deep .el-collapse-item__header:hover .collapseTitle{
  /* color: #ffffff; */
}

.part4 .picture1 .collapse .collapseContent{
  font-size: .18rem;
  height: 1.59rem;
}

.part4 .picture1 .collapse button{
  height: .32rem;
  width: .32rem;
  border-radius: 50%;
  background-color: rgb(235,66,77);
}

.part4 .picture2{
  position: absolute;
  width: 8.08rem;
  height: 5.4rem;
  right: 2rem;
  bottom:.8rem;
  background: url("../../images/advantage_1.jpg");
  background-size: 100%;
}

.part4 .picture2_2{
  background: url("../../images/advantage_2.jpg");
  background-size: 100%;

}

.part4 .picture2_3{
  background: url("../../images/advantage_3.jpg");
  background-size: 100%;
  /*z-index: 3;*/

}

.part4 .picture2_4{
  background: url("../../images/advantage_4.jpg");
  background-size: 100%;

}

.part6{
  height: 10.8rem;
  background-color: #ffffff;
}

.part6 .top{
  position: relative;
  text-align: center;
  color: #212121;
}
.part6 .top p:nth-child(1){
  font-size: .36rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: .25rem;
}

.part6 .top p:nth-child(2){
  width: 8.58rem;
  height: .56rem;
  font-size: .21rem;
  font-weight: 400;
  margin: 0 auto .6rem;
}

.part6 .top button{
  position: absolute;
  top: 0;
  right: 1.38rem;
  width: 1.38rem;
  height: .48rem;
  font-size: .21rem;
  font-weight: 500;
  text-align: center;
  line-height: .48rem;
  color: #fef6f6;
  background-color: #ec414d;
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all .7s;
}

.part6 .top button:hover{
  background-size: 300%;
}

.left {
  float: left;
  margin-left: 1.8rem;
  margin-right: 1rem;
}
.part6 .bottom .left .left_up{
  width: 8.6rem;
  height: 5.35rem;
  margin-bottom: .5rem;
}

.part6 .left_down p{
  height: .42rem;
  font-size: .36rem;
  font-weight: 700;
  color: #ec414d;
  margin-bottom: .25rem;
}

.part6 .left_down div{
  height: .54rem;
  width: 8.6rem;
  font-size: .21rem;
  margin-bottom: .86rem;
}
.part6 .right {
  float: left;
}
.part6 .right .right_item{
  height: 1.4rem;
  width: 6.8rem;
  margin-bottom: .5rem;
  /*background-color: pink;*/
}

.part6 .right .right_item1 .item_pic{
  background-image: url("../../images/part6_pic1.jpg");
  background-size: 100% 100%;
  transition: all 1s;
}

.part6 .right .right_item1 .item_pic:hover{
  background-size: 120% 120%;
}
.part6 .right .right_item2 .item_pic{
  background-image: url("../../images/part6_pic2.jpg");
  background-size: 100% 100%;
  transition: all 1s;
}

.part6 .right .right_item2 .item_pic:hover{
  background-size: 120% 120%;
}
.part6 .right .right_item3 .item_pic{
  background-image: url("../../images/part6_pic3.jpg");
  background-size: 100% 100%;
  transition: all 1s;

}
.part6 .right .right_item3 .item_pic:hover{
  background-size: 120% 120%;
}
.part6 .right .right_item4 .item_pic{
  background-image: url("../../images/part6_pic4.jpg");
  background-size: 100% 100%;
  transition: all 1s;

}

.part6 .right .right_item4 .item_pic:hover{
  background-size: 120% 120%;
}

.part6 .right .item_pic{
  float: left;
  width: 2.24rem;
  height: 1.4rem;
  /*background-color: blue;*/
  margin-right: .25rem;

}
.intro {
  /*float: left;*/
  height: 1.4rem;
  /*background-color: red;*/
  padding-top: .16rem;
}

.intro p{
  /*margin-top: .16rem;*/
  margin-bottom: .25rem;
  font-size: .21rem;
  font-weight: 700;
  color: #212121;
  cursor: pointer;
}

.intro p:hover{
  color:#ed414d ;
}

.intro div{
  font-size: .18rem;
  color: #212121;
}

.part7{
  height: 1.25rem;
  background-image: url("../../images/part7_bac.png");
  background-size: 100% 100%;
  border: .01rem solid transparent;
  padding-top: .14rem;
}

.part7 p:nth-child(1){
  font-weight: 700;
  font-size: .36rem;
  right: 3.6rem;
  color: #ffffff;
  margin-bottom: .05rem;
  text-align: center;

}
.part7 p:nth-child(2){
  font-weight: 700;
  font-size: .18rem;
  right: 5.2rem;
  color: #ffffff;
  bottom: .28rem;
  text-align: center;
}

.part8{
  height: 4.75rem;
  background-color: rgb(244,244,244);
  padding-top: .4rem;
  padding-left: 1.5rem;
  box-shadow: 0rem -0.02rem .1rem -0.02rem rgba(136, 136, 136, 0.49) inset;
}

.part8 .coop{
  width: 16.2rem;
  height: 4rem;
  background: url("../../images/coop.png");
  background-size:100% 100%;
}

.submitRequestInside div{
  height: .68rem;
  line-height: .68rem;
  margin-bottom: .2rem;

}

.submitRequestInside .up{
  height: .52rem;
  /*margin-top: .54rem;*/
  font-size: .36rem;
  color: #212121;
  line-height: .52rem;
}

.submitRequestInside .up i{
  float: left;
  /*float: left;*/
  display: block;
  width: .15rem;
  height: .52rem;
  background-color: #ec414d;
  border-radius: .12rem;

}


.submitRequestInside .up i:nth-child(1){
  margin-left: .6rem;
  margin-right: .08rem;
}

.submitRequestInside .up i:nth-child(2){
  /*margin-left: .6rem;*/
  margin-right: .08rem;
  height: .42rem;
  margin-top: .05rem;
}

.submitRequestInside .up i:nth-child(3){
  /*margin-left: .6rem;*/
  margin-right: .35rem;
  height: .22rem;
  margin-top: .15rem;
}


.submitRequestInside p{
  float: left;
  font-size: .24rem;
  margin-left: .6rem;
  color: #212121;
  font-weight: 500;
}

.submitRequestInside input{
  width: 3.55rem;
  height: .68rem;
  border-radius: .08rem;
  box-shadow: 0 0 .1rem .05rem rgba(0,0,0,.1);
}

.submitRequestInside .star{
  color: #ec414d;
  margin-left: .1rem;
  margin-right: .36rem;
}

.submitRequestInside .verification input{
  width: 2.22rem;
  box-shadow: 0 0 .1rem .05rem rgba(0,0,0,.1);
}

.submitRequestInside .verification button{
  width: 1.32rem;
  height: .68rem;
  color: #ffffff;
  font-size: .18rem;
  background-color: #ec414d;
  border-radius: .08rem;
}

.submitRequestInside .verification .star{
  margin-right: .16rem;
}

.submitRequestInside .release{
  width: 5.1rem;
  height: .7rem;
  text-align: center;
  margin-left: .6rem;
  color: #ffffff;
  font-size: .24rem;
  line-height: .7rem;
  background-color: #ec414d;
  border-radius: .08rem;
  margin-top: .3rem;
}

.submitRequestInside .agreement p{
  font-size: .18rem;
  color: #1db628;
  font-weight: 500;
  /*margin-bottom: -0.3rem;*/
  height: .18rem;
  /*margin: 0;*/
  /*color: #ec414d;*/
}

.submitRequestInside .agreement{

  height: 1.02rem;

  bottom: 1.52rem;
  /*margin-bottom: .28rem;*/
  margin-top: 1.8rem;

}

.submitRequestInside .agreement input{
  width: .2rem;
  height: .2rem;
  margin-left: .6rem;
  box-shadow: none;

}

.submitRequestInside .agreement i{
  font-size: .21rem;
  color: #ec414d;

}

.submitRequestInside .agreement span{
  font-size: .21rem;
  color: #212121;
}
/*动画部分*/
@keyframes rotate {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes  run{
  0%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,.8rem);
  }
}

.part1Title .geo_1{
  animation-name: run;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

.part1Title .geo_2{
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.part1Title .geo_3{
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.part5 .pic1 .geo_2-1{
  animation-name: run;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

.part5 .pic1 .geo_2-2{
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.part5 .pic1 .geo_2-3{
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.part5 .pic1 .geo_2-4{
  animation-name: rotate;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>